.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.bi.bi-diagram {
  /* height: 100%!important;
  background-color: rgb(255 255 255)!important;
  background-size: 50px 50px!important;
  display: flex!important;
  background-image: linear-gradient(0deg, transparent 24%, rgb(240 236 236) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgb(6 5 5 / 5%) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgb(99 91 91 / 5%) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgb(151 146 146 / 5%) 76%, transparent 77%, transparent)!important;
  overflow: scroll!important; */

    /* position: relative;
    width: 100%!important;
    height: calc(100vh - 205px)!important;
    overflow: hidden!important;
    box-sizing: border-box!important;
    /* background: #44559ff2!important; */
    /* background: #516089!important;
    background-size: 10px 10px!important;
    background-image: linear-gradient(to right,#6a6a6a 1px, transparent 1px),linear-gradient(to bottom, #6a6a6a 1px, transparent 1px)!important; */ 
    position: relative;
    width: 100%!important;
    height: calc(100vh - 205px)!important;
    box-sizing: border-box!important;
    /* background: #44559ff2!important; */
    background: #434975f2!important;
    background-size: 10px 10px!important;
    background-image: linear-gradient(to right,#665959 1px, transparent 1px),linear-gradient(to bottom, #665959 1px, transparent 1px)!important;
    overflow: auto!important;
}

.nodebtn{
  /* background: #4c64cb;
    color: white;
    border-radius: 2px;
    margin-right: 3px; */
      /* background: rgb(104,115,150);
      color: #fff;
      border-radius: 2px;
      margin-right: 3px; */
      /* border: 1px solid #4d60a1; */

      background: rgb(104,115,150);
      color: #fff;
      border-radius: 2px;
      margin-right: 3px;
      /* border: 1px solid #4d60a1; */
      padding: 11px;
      height: 34px;
      font-size: 14px;
      border-radius: 5px;
      padding-top: 6px;
      margin-top: 7px;
  
}

/* 
.bi.bi-diagram .bi.bi-diagram-node.bi-diagram-node-default{
  width: 97px!important;
    height: 70px!important;
    text-align: center!important;
    border: 0.07rem solid #1d67a1!important;
    background-color: #1c537f!important;
    color: white!important;
} */

.bi.bi-diagram .bi.bi-diagram-node.bi-diagram-node-default {
  width: 111px!important;
  height: 21px!important;
  text-align: left!important;
  border: 0.07rem solid #cecece!important;
  background-color: #1d1d1d!important;
  color: white!important;
  border-left: 6px solid green!important;
}
.bi.bi-diagram .bi.bi-diagram-node.bi-diagram-node-default .bi-port-wrapper {
  margin-top: -19px!important;
}

.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper .bi-output-ports .bi-diagram-port {
    background: white!important;
    height: 15px!important;
    width: 14px!important;
    border: 1px solid gray!important;
}

.ant-drawer-content .ant-col-24.ant-form-item-label > label, .ant-drawer-content .ant-col-xl-24.ant-form-item-label > label, .ant-drawer-content .ant-form-vertical .ant-form-item-label > label {
  margin: 0px;
  color: white;
  font-size: 12px;
  padding: 0;
}

.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-path {
  stroke: #adadad!important;
  stroke-width: 0.15rem!important;
} 

.bi.bi-diagram .bi-link-canvas-layer{
  z-index: 53!important;
}

.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-ghost{
  /* fill: teal!important; */
  /* stroke: teal!important; */
}


.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-diagram-link-label {
  /* transform: translate(50%,50%)!important; */
}
/* 

.bi-link-path::after {
  content: " - Remember this";
  display: block;
  background: #c00;
  height: 29px;
  width: 100%;

  position: absolute;
  bottom: -29px;
} */

/* #addicon .bi.bi-diagram-port::before { */
/* #addicon .bi.bi-diagram-port::before { */
  /* content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='100' cy='50' r='40' stroke='black' stroke-width='2' fill='red'/%3E%3Cpolyline points='20,20 40,25 60,40 80,120 120,140 200,180' style='fill:none;stroke:black;stroke-width:3'/%3E%3C/svg%3E ")!important; */
  /* content:">";
  display: block;
  color: #a8a9aa;
  font-size: 27px;
  margin-top: -14px;
  font-weight: bolder;
} */

.arrow {
  border: solid #8e8e8e;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  float: left;
  margin-top: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.ant-drawer-body .ant-input, .ant-drawer-body .ant-select-selector {
  padding: 0;
  border: 0;
  border-bottom: 1px solid gray;
  border-radius: 0px;
}
.ant-drawer-body .ant-col-24.ant-form-item-label, .ant-select{
  padding: 0;
}

.ant-drawer-body .ant-input-focused, .ant-input:focus, .ant-drawer-body .ant-input-focused, .ant-input:focus{
  box-shadow: none;
}

.ant-drawer-body .ant-drawer-header, .ant-drawer-header-no-title{
  padding: 9px;
  padding-top: 9px;
}
.ant-drawer-body .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: 0;
  border-bottom: 1px solid gray;
  border-radius: 0;
}
.ulmenu{
  border-bottom: 1px solid #e3e3e3;
    line-height: 27px;
}
.ulmenu span{
color: white;
cursor: pointer;
}
.ant-popover-inner-content{
  background: #687396!important;
}
